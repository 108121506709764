import CoreModel from "../service/CoreModel";
import Service from "./Service";
import moment from "moment";
import ReactDom from "react-dom";
import jwtDecode from "jwt-decode";

export default class ViewModel extends CoreModel {
  constructor() {
    super();
    this.service = new Service();

    this.props = {};
    this.inputs = [];
    this.state = {
      cidadeAtuacao: [],
      api_waypoints: [],
      api_valores: [],
      api_rotas: [],
      showColapseSolicitante: true,
      showColapseOrigem: false,
      showColapseMap: false,
      showColapseModalidade: false,
      showColapsePagamento: false,
      showColapseOutrasOpcoes: false,
      showColapseDestino: false,
      pricesselecionado: {},
      maximodestinos: 3,
      tiposelecionado: "",
      tiposolicitacao: 1,
      showDetalhe: false,
      showModal: false,
      favorito_id: null,
      corrida: {},
      moip: null,
      pricesEscolhido: null,
      alertDisplay: "display-hide",
      alertText: "Por favor preencha o número no endereço!",
      band: "",
      km: "",
      valorMin: "",
      taxaRet: "",
      perfil: "",
      role: 10,
      pagamentos_api: [],
      markerLocal: [
        {
          lat: "",
          lng: "",
          img_src: "",
          label: "",
        },
      ],
      markerDest: [
        {
          lat: "",
          lng: "",
          img_src: "",
        },
      ],
      motoMarker: [
        {
          lat: "",
          lng: "",
          img_src: "",
        },
      ],
      mostrardetalhesuser: false,
      valorCorridaFloat: null,
      valorExecutivoFloat: null,
      dataCompleta: null,
      valorExecutivo: "",
      valorNormal: "",
      distance: "",
      modal_observacao: false,
      distanceValue: "",
      options: [],
      telefoneMask: "(99) 99999-9999",
      origemLatLng: { lat: "", lng: "" },
      destinoLatLng: { lat: "", lng: "" },
      apelido: "",
      telefone: "",
      passageiro: "",
      origem: "",
      origemautocomplete: {},
      fullAddress: "",
      d_fullAddress: "",
      ac_o_pos: null,
      numero: "",
      referencia: "",
      complemento: "",
      destino: "",
      destinos: [
        {
          autocomplete: {},
          destino: "",
          numeroDestino: "",
          cidadeDestino: "",
          obs: "",
          item: "",
          valor: 0,
          pagamento: "",
          contato: "",
          parada: false,
          retorno: false,
          fullAddress: "",
          ac_pos: null,
        },
      ],
      numeroDestino: "",
      cidadeDestino: "",
      address: "",
      pagtoOptions: [],
      pagtoSelected: "112",
      taxiCM: "active",
      taxiEX: "",
      showCartao: false,
      tipo_cor: false,
      corSelected: [],
      favoritos: [],
      carroSelected: [],
      animalCheck: false,
      cadeiranteCheck: false,
      portaMalasCheck: false,
      wifiCheck: false,
      showAgenda: "none",
      praJaCheck: true,
      agendCheck: false,
      horaAgend: moment(),
      dataAgend: this.minimalDate(),
      favCheck: false,
      podepedir: true,
      validation: "",
      antecipar: "20",
      selecionado: false,
      haveDestino: false,
      qtdCorridas: 1,
      telefoneempresa: "",
      limitecarros: 10,
      solicitarpreferencias: true,
      solicitarpreferenciasmoto: true,
      tipocentral: 1,
      central_data: {},
      empresa_data: {},
      user_data: {},
      func_data: {},
      sugest_funcs: [],
      motoristas: [],
      sugest_nomeFunc: "",
      departamentoss: [],
      departamentosz: [],
      selection: 0,
      selected: {
        telefone: "",
        passageiro: "",
        identificacao: "",
        origem_endereco: "",
        origem_numero: "",
        origem_cidade: "",
        referencia: "",
        complemento: "",
        destino_endereco: "",
        destino_numero: "",
        destino_cidade: "",
        formadepagamento: "",
        origem_latitude: null,
        origem_longitude: null,
        destino_latitude: null,
        destino_longitude: null,
      },
      suggestion: {
        departamento: "",
        usuario: "",
        empresa: "",
        matricula: "",
        valorlimit: "",
        role: "",
        voucher_roles_descricao: "",
        voucher_usuarios_email: "",
        voucher_usuarios_username: "",
        voucher_usuarios_nome: "",
        voucher_usuarios_telefone: "",
        status: "",
        selection: "",
      },
    };

    this.showHideDestino = this.showHideDestino.bind(this);
    this.showHideSolicitante = this.showHideSolicitante.bind(this);
    this.showHideOrigem = this.showHideOrigem.bind(this);
    this.showHideMap = this.showHideMap.bind(this);
    this.showHidePagamento = this.showHidePagamento.bind(this);
    this.setTipoPagamentoNew = this.setTipoPagamentoNew.bind(this);
    this.setTipoPagamento = this.setTipoPagamento.bind(this);
    this.newPag = this.newPag.bind(this);
    this.setaTipoSolicitacao = this.setaTipoSolicitacao.bind(this);
    this.funcDep = this.funcDep.bind(this);
    this.dateHandleChange = this.dateHandleChange.bind(this);
    this.calculaValor = this.calculaValor.bind(this);
    this.getSelectedOptionsValue = this.getSelectedOptionsValue.bind(this);
    this.pegaDep = this.pegaDep.bind(this);
    this.salvarFavorito = this.salvarFavorito.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.hideDetalhe = this.hideDetalhe.bind(this);
    this.showDetalhe = this.showDetalhe.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleChangeDados = this.handleChangeDados.bind(this);
    this.getPrices = this.getPrices.bind(this);
    this.disableMinute = this.disableMinute.bind(this);
    this.disableHour = this.disableHour.bind(this);
    this.disabledHours = this.disabledHours.bind(this);
    this.disabledMinutes = this.disabledMinutes.bind(this);
    this.getFavoritos = this.getFavoritos.bind(this);
    this.setFavorito = this.setFavorito.bind(this);
    this.BlockDay = this.BlockDay.bind(this);
    this.onBlurNomeFunc = this.onBlurNomeFunc.bind(this);
    this.onChangeNomeFunc = this.onChangeNomeFunc.bind(this);
    this.afterUpdateUser = this.afterUpdateUser.bind(this);
    this.showModalObservacao = this.showModalObservacao.bind(this);
    this.hideModalObservacao = this.hideModalObservacao.bind(this);
    this.hideModalObservacaoDestino =
      this.hideModalObservacaoDestino.bind(this);
    this.showModalObservacaoDestino =
      this.showModalObservacaoDestino.bind(this);
    this.newSendTaxiReal = this.newSendTaxiReal.bind(this);
    this.hideModalPix = this.hideModalPix.bind(this);
    this.showModalPix = this.showModalPix.bind(this);
    this.savePagamentoApi = this.savePagamentoApi.bind(this);
    this.getCidadeAtuacao = this.getCidadeAtuacao.bind(this);
    this.checkCidadeAtuacao = this.checkCidadeAtuacao.bind(this);
  }

  getCidadeAtuacao() {
    this.service.getCidadeAtuacao().then((result) => {
      console.log('checkCidadeAtuacao origem', result)
      this.setState({
        cidadeAtuacao: result,
      });
    });
  }

  checkCidadeAtuacao()  {
    let cidade = (this.state.cidade + '-' + this.state.estado).toUpperCase()
    for (let i = 0; i < this.state.cidadeAtuacao.length; i++) {
      if (this.state.cidadeAtuacao[i].cidade === cidade) {
        return this.state.cidadeAtuacao[i].tipos;
      }
    }
    return [];
  }

  afterUpdateUser(value) {
    this.service.getFuncsByName(value).then((result) => {
      this.setState({
        sugest_funcs: result,
      });
    });
  }

  showHideSolicitante() {
    this.setState({
      showColapseSolicitante: !this.state.showColapseSolicitante,
    });
  }
  showHideOrigem() {
    this.setState({
      showColapseOrigem: !this.state.showColapseOrigem,
    });
  }
  async showHideMap(callback) {
    await this.setState({ showColapseMap: !this.state.showColapseMap });
    if (!!callback) {
      callback();
    }
  }

  showHidePagamento() {
    this.setState({
      showColapsePagamento: !this.state.showColapsePagamento,
    });
  }

  showHideOpcoes() {
    this.setState({
      showColapseOutrasOpcoes: !this.state.showColapseOutrasOpcoes,
    });
  }
  showHideDestino() {
    this.setState({
      showColapseDestino: !this.state.showColapseDestino,
    });
  }

  setTipoPagamentoNew(tipo, pgtos) {
    this.setState({
      tiposelecionado: tipo,
      pagamentos_api: pgtos,
      pagtoSelectedApi: pgtos[0].id,
    });
  }
  setTipoPagamento(tipo, pgto) {
    let prices = {};
    if (this.state.prices) {
      if (this.state.prices[tipo]) {
        prices = this.state.prices[tipo];
      }
    }
    if (!pgto) {
      let pgtofiltrados = this.state.pagtoOptions.filter(
        (x) => Number(x.id) === Number(112)
      );
      if (pgtofiltrados.length > 0) {
        pgto = "112";
      } else {
        pgto = "10";
      }
    }
    this.setState({
      tiposelecionado: tipo,
      pricesselecionado: prices,
      pagtoSelected: pgto,
    });
  }

  async newPag() {
    await this.setState({
      api_waypoints: [],
      api_valores: [],
      api_rotas: [],
      showPix: false,
      pixCpf: "",
      showColapseSolicitante: true,
      showColapseOrigem: false,
      showColapseMap: false,
      showColapseModalidade: false,
      showColapsePagamento: false,
      showColapseDestino: false,
      pricesselecionado: {},
      empresa_latitude: -25,
      empresa_longitude: -49,
      tiposelecionado: "",
      tiposolicitacao: 1,
      newPag: true,
      showModal: false,
      corrida: {},
      favorito_id: null,
      moip: null,
      prices: {},
      c_points: null,
      pricesEscolhido: null,
      mostrardetalhesuser: false,
      alertDisplay: "display-hide",
      alertText: "Por favor preencha o número no endereço!",
      band: "",
      km: "",
      valorMin: "",
      taxaRet: "",
      perfil: "",
      role: 10,
      markerLocal: [
        {
          lat: "",
          lng: "",
          img_src: "",
          label: "",
        },
      ],
      markerDest: [
        {
          lat: "",
          lng: "",
          img_src: "",
        },
      ],
      motoMarker: [
        {
          lat: "",
          lng: "",
          img_src: "",
        },
      ],
      valorCorridaFloat: null,
      dataCompleta: null,
      valorExecutivo: "",
      valorNormal: "",
      distance: "",
      modal_observacao: false,
      distanceValue: "",
      options: [],
      telefoneMask: "(99) 99999-9999",
      origemLatLng: { lat: "", lng: "" },
      destinoLatLng: { lat: "", lng: "" },
      apelido: "",
      telefone: "",
      passageiro: "",
      origem: "",
      origemautocomplete: {},
      fullAddress: "",
      d_fullAddress: "",
      ac_o_pos: null,
      numero: "",
      referencia: "",
      complemento: "",
      destino: "",
      destinos: [
        {
          autocomplete: {},
          destino: "",
          numeroDestino: "",
          cidadeDestino: "",
          obs: "",
          item: "",
          valor: 0,
          pagamento: "",
          contato: "",
          parada: false,
          retorno: false,
          fullAddress: "",
          ac_pos: null,
        },
      ],
      numeroDestino: "",
      cidadeDestino: "",
      address: "",
      pagtoOptions: [],
      pagtoSelected: "112",
      taxiCM: "active",
      taxiEX: "",
      showCartao: false,
      tipo_cor: false,
      corSelected: [],
      favoritos: [],
      carroSelected: [],
      animalCheck: false,
      cadeiranteCheck: false,
      portaMalasCheck: false,
      wifiCheck: false,
      retornoOriginalCheck: false,
      showAgenda: "none",
      praJaCheck: true,
      agendCheck: false,
      horaAgend: moment(),
      dataAgend: moment(),
      favCheck: false,
      podepedir: true,
      validation: "",
      antecipar: "20",
      selecionado: false,
      haveDestino: false,
      qtdCorridas: 1,
      telefoneempresa: "",
      limitecarros: 10,
      solicitarpreferencias: true,
      solicitarpreferenciasmoto: true,
      tipocentral: 1,
      central_data: {},
      empresa_data: {},
      user_data: {},
      func_data: {},
      sugest_funcs: [],
      sugest_nomeFunc: "",
      departamentoss: [],
      departamentosz: [],
      selected: {
        telefone: "",
        passageiro: "",
        identificacao: "",
        origem_endereco: "",
        origem_numero: "",
        origem_cidade: "",
        referencia: "",
        complemento: "",
        destino_endereco: "",
        destino_numero: "",
        destino_cidade: "",
        formadepagamento: "",
        origem_latitude: null,
        origem_longitude: null,
        destino_latitude: null,
        destino_longitude: null,
      },
      suggestion: {
        departamento: "",
        usuario: "",
        empresa: "",
        matricula: "",
        valorlimit: "",
        role: "",
        voucher_roles_descricao: "",
        voucher_usuarios_email: "",
        voucher_usuarios_username: "",
        voucher_usuarios_nome: "",
        voucher_usuarios_telefone: "",
        status: "",
        selection: "",
      },
    });
  }
  async setaTipoSolicitacao(tipo) {
    await this.setState({
      tiposolicitacao: tipo,
      tiposolicitacao_str: "",
      retornoOriginalCheck: tipo === 1 ? false : true,
      destinos: this.state.destinos.map((el) => {
        return { ...el, parada: tipo === 2 ? true : false };
      }),
    });
  }

  hideModalPix() {
    this.setState({ showPix: false, podepedir: true });
  }
  showModalPix() {
    this.setState({ showPix: true });
  }

  funcDep(dep) {
    let retorno;
    if (dep !== "") {
      retorno = this.state.departamentoss.map((m) => {
        if (Number(m.id) === Number(dep)) {
          if (
            Number(m.valorlimit) >
            Number(m.saldo_gasto) + Number(this.state.valorCorridaFloat)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      let achou = retorno.indexOf(true);
      return achou === -1 ? false : true;
    }
  }

  dateHandleChange(date) {
    if (moment(date) < moment()) {
      date = moment()
    }
    this.setState({
      dataAgend: date,
      horaAgend: moment(),
    });
  }

  minimalDate() {
    const start = moment().add(9, "minutes")
const remainder = 15 - (start.minute() % 15);
 
return moment(start).add(remainder, "minutes");


  }
  async calculaValor() {
    let departamento = Number(this.state.selection);
    let funcionario = this.state.suggestion.id;
    let waypoints = [];

    waypoints.push({
      latitude: this.state.origemLatLng.lat,
      longitude: this.state.origemLatLng.lng,
    });

    if (this.state.destinos.length > 0) {
      for (let tam = 0; tam < this.state.destinos.length; tam++) {
        let newObj = {
          latitude: this.state.destinos[tam].lat,
          longitude: this.state.destinos[tam].lng,
        };
        waypoints.push(newObj);
      }
    }
    let dados = await this.service.calculaValorApi(
      funcionario,
      departamento,
      waypoints
    );
    this.setState({
      api_waypoints: waypoints,
    });
    if (dados.valores) {
      
      await this.setState({
        api_valores: dados.valores,
        api_rotas: dados.rota,
      });


      if (dados.valores.length > 0) {
        console.log('dados.valores[0]',dados.valores[0])
        this.setTipoPagamentoNew(dados.valores[0].id, dados.valores[0].pagamentos);
      }
    }
  }

  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value);
    }

    return values;
  }
  async pegaDep() {
    let id = await this.service.getCookie("empresa-id");

    let resultado = await this.service.getDepartamentoslist(id);

    resultado = resultado.map((m) => {
      return {
        ...m,
        text: m.identificacao,
      };
    });
    this.setState({ departamentoss: resultado });
  }
  async willMount() {
    this.setaTipoSolicitacao(1);
    let token = this.service.getCookie("portal-token");
    let data = jwtDecode(token);
    this.service.getFuncById(data.id).then((result) => {
      if (result.length > 0) {
        this.setState({
          perfil: result[0].voucher_roles_descricao,
          role: result[0].role,
          favorito_id: result[0].favorito_id,
        });
      }
    });

    await this.pegaDep();
    let f = await this.getFavoritos();

    if (this.state.favorito_id && !this.state.newPag) {
      let mf = f.filter((x) => x.id === this.state.favorito_id);
      if (mf.length > 0) this.setFavorito(mf[0], 999);
    } else {
      setTimeout(() => {
        //ReactDom.findDOMNode(this.inputs["enderecoorigem999"]).focus();
      }, 1000);
    }

    let central_id = this.service.getCookie("central-id");
    let empresa_id = this.service.getCookie("empresa-id");
    let user_data = await this.service.getUserById(data.id);
    let central_data = await this.service.getCentral(central_id);
    let empresa_data = await this.service.getEmpresa(empresa_id);
    let func_data = await this.service.getFuncById(data.id);
    this.setState({
      central_data: central_data,
      empresa_data: empresa_data,
      user_data: user_data,
      func_data: func_data[0],
      empresa_latitude: empresa_data.latitude,
      empresa_longitude: empresa_data.longitude,
    });

    if (central_data) {
      let valores = central_data.valores;
      let cnl = false;
      let prm = false;
      if (valores) {
        let prm_check =
          valores[valores.findIndex((obj) => obj.sigla === "prm")];
        let cnl_check =
          valores[valores.findIndex((obj) => obj.sigla === "cnl")];
        cnl = cnl_check.ativo;
        prm = prm_check.ativo;
      }

      await this.setState({
        tipocentral: central_data.tipo_central,
        cnl_ativo: cnl,
        prm_ativo: prm,
        taxiCM: cnl ? "active" : "",
        taxiEX: cnl ? "" : "active",
      });
    }
    if (empresa_data) {
      let empresa = empresa_data;
      let telefoneempresa = empresa.telefone;
      let limitecarros = empresa.limitecarros;
      let solicitarpreferencias = empresa.solicitarpreferencias;
      await this.setState({
        telefoneempresa: telefoneempresa,
        limitecarros: limitecarros,
        solicitarpreferencias: solicitarpreferencias,
      });
    }
    if (this.state.func_data) {
      let func_data_mod = this.state.func_data;
      if (!func_data_mod.valorlimit) {
        func_data_mod.valorlimit = 0;
      }
      if (func_data_mod.valorlimit === 0) {
        func_data_mod.valorlimit = 100000;
      }

      let depz = [];
      let depzs = 0;
      for (var dp in this.state.departamentoss) {
        let pz = this.state.departamentoss[dp];
        if (
          (func_data_mod.departamentos &&
            func_data_mod.departamentos.indexOf(pz.id) > -1) ||
          this.state.role < 7
        ) {
          depz.push(pz);
        }
      }
      if (depz.length > 0) {
        depzs = depz[0].id;
      } else {
        depz.push({
          calendar: null,
          empresa: 0,
          id: 0,
          identificacao: "NENHUM",
          text: "NENHUM",
          valorlimit: null,
        });
        depzs = 0;
      }
      if (func_data_mod.departamentos.length > 0) {
        depzs = func_data_mod.departamentos[0];
      }


       let useTelafone = this.state.func_data.voucher_usuarios_telefone
       ? this.state.func_data.voucher_usuarios_telefone
       : empresa_data.telefone
      console.log('useTelafone', useTelafone)
        if (useTelafone.charAt(0) == "0") {
          useTelafone = useTelafone.slice(1, useTelafone.length);
        }
        console.log('this.state.func_data.', this.state.func_data)
        let v = useTelafone.replace(/[^0-9]/g, '');
        let telefoneMask = "(99) 99999-9999";
        if (v.length > 10) {
          telefoneMask = "(99) 99999-9999";
        } else {
          telefoneMask = "(99) 9999-9999";
        }

        console.log('useTelafone', v,telefoneMask)
      this.setState({
        telefone: v,
        telefoneMask: telefoneMask,
        uuid: this.state.func_data.uuid,
        passageiro: this.state.func_data.voucher_usuarios_nome,
        suggestion: func_data_mod,
        sugest_nomeFunc: this.state.func_data.voucher_usuarios_nome,
        selection: depzs,
        departamentosz: depz,
        mostrardetalhesuser: false,
      });
    }
  }

  didMount() {
    let empresaID = this.service.getCookie("empresa-id");
    this.getCidadeAtuacao()
    this.service.getEmpresa(empresaID).then((result) => {
      let dayOfTheWeek = moment().format("d");
      let hourOfTheDay = moment().hour();
      if (
        (dayOfTheWeek >= 1 &&
          dayOfTheWeek <= 5 &&
          hourOfTheDay >= 6 &&
          hourOfTheDay < 20) ||
        (dayOfTheWeek === 6 && hourOfTheDay >= 6 && hourOfTheDay < 13)
      ) {
        this.setState({
          comissao: result.comissao ? result.comissao.toFixed(2) : 0,
          km: result.km_b1,
          band: result.band_b1,
          valorMin: result.valor_minimo,
          taxaRet: result.taxa_retorno,
          desc_v: result.desconto,
        });
      } else {
        this.setState({
          comissao: result.comissao ? result.comissao.toFixed(2) : 0,
          km: result.km_b2,
          band: result.band_b2,
          valorMin: result.valor_minimo,
          taxaRet: result.taxa_retorno,
          desc_v: result.desconto,
        });
      }
      if (result.maximodestinos) {
        this.setState({ maximodestinos: result.maximodestinos });
      } else {
        this.setState({ maximodestinos: 3 });
      }
    });
    this.service.getOptions("pagto").then((result) => {
      this.setState({ pagtoOptions: result });
    });
  }

  salvarFavorito() {
    let s = this.state.selected;

    if (s.identificacao === "") {
      alert("Por favor preencha o apelido!");
      return;
    }
    if (s.telefone === "") {
      alert("Por favor preencha o telefone!");
      return;
    }
    if (s.passageiro === "") {
      alert("Por favor preencha o passageiro!");
      return;
    }
    if (s.origem_endereco === "") {
      alert("Por favor preencha o endereço de origem!");
      return;
    }
    if (s.origem_numero === "") {
      alert("Por favor preencha o número de origem!");
      return;
    }

    if (this.state.novo) {
      this.setState(
        {
          selected: {
            ...s,
            telefone: s.telefone.toString(),
            usuario: this.props.childProps.user.id,
            origem_latitude: this.state.origemLatLng.lat,
            origem_longitude: this.state.origemLatLng.lng,
            destino_latitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lat
                ? this.state.destinoLatLng.lat
                : null,
            destino_longitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lng
                ? this.state.destinoLatLng.lng
                : null,
          },
        },
        async () => {
          await this.service.criarFavorito(this.state.selected);

          this.setState({
            selected: {
              telefone: "",
              passageiro: "",
              identificacao: "",
              origem_endereco: "",
              origem_numero: "",
              origem_cidade: "",
              referencia: "",
              complemento: "",
              destino_endereco: "",
              destino_numero: "",
              destino_cidade: "",
              formadepagamento: 10,
              origem_latitude: null,
              origem_longitude: null,
              destino_latitude: null,
              destino_longitude: null,
            },
          });
          this.getFavoritos();
          this.setState({ showEditFavoritos: false });
        }
      );
    } else if (this.state.editar) {
      this.setState(
        {
          selected: {
            ...s,
            telefone: s.telefone.toString(),
            origem_latitude: this.state.origemLatLng.lat,
            origem_longitude: this.state.origemLatLng.lng,
            destino_latitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lat
                ? this.state.destinoLatLng.lat
                : null,
            destino_longitude:
              this.state.destinoLatLng && this.state.destinoLatLng.lng
                ? this.state.destinoLatLng.lng
                : null,
          },
        },
        () => {
          this.service.alterarFavorito(this.state.selected).then(() => {
            this.setState({
              selected: {
                telefone: "",
                passageiro: "",
                identificacao: "",
                origem_endereco: "",
                origem_numero: "",
                origem_cidade: "",
                referencia: "",
                complemento: "",
                destino_endereco: "",
                destino_numero: "",
                destino_cidade: "",
                formadepagamento: 112,
                origem_latitude: null,
                origem_longitude: null,
                destino_latitude: null,
                destino_longitude: null,
              },
            });
          });
          this.getFavoritos();
          this.setState({ showEditFavoritos: false }, () => {
            //Dialog.resetOptions();
            //this.dialog.showAlert("Favorito editado com sucesso!");
          });
        }
      );
    }
  }

  savePagamentoApi(id) {
    console.log('pagamento selecionado', id)
    this.setState({ pagtoSelectedApi: id });
  }

  hideModalObservacaoDestino() {
    this.setState({
      modal_observacao_destino: false,
      modal_observacao_destino_id: null,
    });
  }

  showModalObservacaoDestino(id) {
    this.setState({
      modal_observacao_destino: true,
      modal_observacao_destino_id: id,
    });
  }

  hideModalObservacao() {
    this.setState({ modal_observacao: false });
  }
  showModalObservacao() {
    this.setState({ modal_observacao: true });
  }
  hideModal() {
    this.setState({ showModal: false });
  }

  hideDetalhe() {
    this.setState({ showDetalhe: false });
  }
  showDetalhe(tipo) {
    let s = this.state.prices[tipo];
    if (s) {
      this.setState({ pricesEscolhido: s, showDetalhe: true });
    }
  }
  handleValueChange(value) {
    this.setState({ horaAgend: value });
  }

  async handleChangeDados(e) {
    await this.setState({ retornoOriginalCheck: e.target.checked });
    this.calculaValor();
  }

  disableMinute(minute) {
    let vet = [];
    for (let i = 0; i < 60; i++) {
      if (i < minute) {
        vet.push(i);
      }
    }
    return vet;
  }

  disableHour(hour) {
    let vet = [];
    for (let i = 0; i < 24; i++) {
      if (i < hour) {
        vet.push(i);
      }
    }
    return vet;
  }

  disabledHours() {
    let date = moment(this.state.dataAgend).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");
    let hora = Number(moment().hour());

    if (date === today) {
      return [...Array(hora).keys()];
    } else {
      return [];
    }
  }
  onBlurNomeFunc(highlightedSuggestion) {
    if (highlightedSuggestion) {
      this.setState({
        sugest_nomeFunc: highlightedSuggestion.voucher_usuarios_nome,
      });
    }
  }

  onChangeNomeFunc(newValue) {
    this.setState({ sugest_nomeFunc: newValue });
  }

  async setFavorito(fav, i) {
    this.changeEndereco(fav.autocomplete, true, i);
    if (parseInt(i) === 999) {
      if (fav.coordenada) {
        this.setState({
          referencia: fav.coordenada,
        });
      }
      if (fav.complemento) {
        this.setState({
          complemento: fav.complemento,
        });
      }
    }
    this.input.focus();
  }

  BlockDay() {
    var valida = false;
    if (this.state.suggestion.calendar !== null) {
      this.state.suggestion.calendar.map((m) => {
        if (
          m.seg === true &&
          moment().isoWeekday() === 1 &&
          moment().isBetween(
            moment(m.ini, "HH:mm"),
            moment(m.fim, "HH:mm", "second")
          )
        ) {
          valida = true;
        } else {
          if (
            m.ter === true &&
            moment().isoWeekday() === 2 &&
            moment().isBetween(
              moment(m.ini, "HH:mm"),
              moment(m.fim, "HH:mm", "second")
            )
          ) {
            valida = true;
          } else {
            if (
              m.qua === true &&
              moment().isoWeekday() === 3 &&
              moment().isBetween(
                moment(m.ini, "HH:mm"),
                moment(m.fim, "HH:mm", "second")
              )
            ) {
              valida = true;
            } else {
              if (
                m.qui === true &&
                moment().isoWeekday() === 4 &&
                moment().isBetween(
                  moment(m.ini, "HH:mm"),
                  moment(m.fim, "HH:mm", "second")
                )
              ) {
                valida = true;
              } else {
                if (
                  m.sex === true &&
                  moment().isoWeekday() === 5 &&
                  moment().isBetween(
                    moment(m.ini, "HH:mm"),
                    moment(m.fim, "HH:mm", "second")
                  )
                ) {
                  valida = true;
                } else {
                  if (
                    m.sab === true &&
                    moment().isoWeekday() === 6 &&
                    moment().isBetween(
                      moment(m.ini, "HH:mm"),
                      moment(m.fim, "HH:mm", "second")
                    )
                  ) {
                    valida = true;
                  } else {
                    if (
                      m.dom === true &&
                      moment().isoWeekday() === 7 &&
                      moment().isBetween(
                        moment(m.ini, "HH:mm"),
                        moment(m.fim, "HH:mm", "second")
                      )
                    ) {
                      valida = true;
                    }
                  }
                }
              }
            }
          }
        }
        return valida;
      });
    }

    if (valida === true) {
      return true;
    } else {
      return false;
    }
  }

  disabledMinutes(h) {
    let date = moment(this.state.dataAgend).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");
    let hora = Number(moment().hour());
    let minuto = Number(moment().minute());
    if (date === today) {
      if (hora > h) {
        return [];
      } else {
        return [...Array(minuto + 10).keys()];
      }
    } else {
      return [];
    }
  }

  async getPrices() {
    this.calculaValor();
    if (this.state.origemLatLng.lat === "") {
      return;
    }
    if (this.state.origemLatLng.lng === "") {
      return;
    }
    if (this.state.destinoLatLng.lat === "") {
      return;
    }
    if (this.state.destinoLatLng.lng === "") {
      return;
    }

    const bounds = {
      ne: {
        lat: this.state.origemLatLng.lat,
        lng: this.state.origemLatLng.lng,
      },
      sw: {
        lat: this.state.destinoLatLng.lat,
        lng: this.state.destinoLatLng.lng,
      },
    };

    if (this.state.origemLatLng.lat > bounds.ne.lat) {
      bounds.ne.lat = this.state.origemLatLng.lat;
    }
    if (this.state.origemLatLng.lng > bounds.ne.lng) {
      bounds.ne.lng = this.state.origemLatLng.lng;
    }
    if (this.state.origemLatLng.lat < bounds.sw.lat) {
      bounds.sw.lat = this.state.origemLatLng.lat;
    }
    if (this.state.origemLatLng.lng < bounds.sw.lng) {
      bounds.sw.lng = this.state.origemLatLng.lng;
    }

    if (this.state.destinoLatLng.lat !== "") {
      if (this.state.destinoLatLng.lat > bounds.ne.lat) {
        bounds.ne.lat = this.state.destinoLatLng.lat;
      }
      if (this.state.destinoLatLng.lng > bounds.ne.lng) {
        bounds.ne.lng = this.state.destinoLatLng.lng;
      }
      if (this.state.destinoLatLng.lat < bounds.sw.lat) {
        bounds.sw.lat = this.state.destinoLatLng.lat;
      }
      if (this.state.destinoLatLng.lng < bounds.sw.lng) {
        bounds.sw.lng = this.state.destinoLatLng.lng;
      }
    }

    let origen = {
      origem: this.state.origem,
      numero: this.state.numero,
      cidade: this.state.cidade,
      fullAddress: this.state.fullAddress,
      obs: this.state.referencia,
      lat: this.state.origemLatLng.lat,
      lng: this.state.origemLatLng.lng,
    };

    this.service
      .calcula(
        origen,
        this.state.destinoLatLng,
        this.state.destinos,
        null,
        this.state.retornoOriginalCheck
      )
      .then((result) => {
        let org = [];
        let pricesTransporte = result.valores.transporte;

        if (this.state.tipocentral === 2) {
          if (this.state.tiposolicitacao === 1) {
            pricesTransporte.pop.origemdatabela = "padrão";
            pricesTransporte.mot.origemdatabela = "padrão";
          }
          if (this.state.tiposolicitacao === 2) {
            pricesTransporte.cnl.origemdatabela = "padrão";
            pricesTransporte.pop.origemdatabela = "padrão";
          }
          if (this.state.tiposolicitacao === 3) {
            pricesTransporte.cnl.origemdatabela = "padrão";
            pricesTransporte.mot.origemdatabela = "padrão";
          }
        }

        let points = result.pontos;

        if (pricesTransporte.vet.origemdatabela === "padrão") {
          delete pricesTransporte.vet;
        } else {
          org.push("vet");
        }
        if (pricesTransporte.cnl.origemdatabela === "padrão") {
          delete pricesTransporte.cnl;
        } else {
          org.push("cnl");
        }
        if (pricesTransporte.prm.origemdatabela === "padrão") {
          delete pricesTransporte.prm;
        } else {
          org.push("prm");
        }
        if (pricesTransporte.ldy.origemdatabela === "padrão") {
          delete pricesTransporte.ldy;
        } else {
          org.push("ldy");
        }

        if (pricesTransporte.mot.origemdatabela === "padrão") {
          delete pricesTransporte.mot;
        } else {
          org.push("mot");
        }
        if (pricesTransporte.cdo.origemdatabela === "padrão") {
          delete pricesTransporte.cdo;
        } else {
          org.push("cdo");
        }
        if (pricesTransporte.c15.origemdatabela === "padrão") {
          delete pricesTransporte.c15;
        } else {
          org.push("c15");
        }
        if (pricesTransporte.pop.origemdatabela === "padrão") {
          delete pricesTransporte.pop;
        } else {
          org.push("pop");
        }

        this.setState({
          prices: pricesTransporte,
          c_points: points,
          cidadeDestino: result.cidadeFim,
          cidade: result.cidadeInic,
          estado: result.estadoInic,
          estadodestino: result.estadoFim,
        });

        if (org.length > 0) {
          this.setTipoPagamento(org[0]);
        }
      });
  }

  modalFavoritoHide() {
    this.setState({ showfavmodal: false });
  }
  setInpudDestino(input, key) {
    if (key === "enrerecodestino0") {
      this.input = input;
    }
    this.inputs[key] = input;
  }
  async getFavoritos() {
    let result = await this.service.getFavoritosNew();
    await this.setState({ favoritos: result });
    return result;
  }

  deleteFavorito(id) {
    this.service.deleteFavorito(id).then(() => {
      this.getFavoritos();
      //Dialog.resetOptions();
      //this.dialog.showAlert("Favorito deletado com sucesso!");
    });
  }

  driversAround(lat, lon) {
    this.service.driversAround(lat, lon).then((response) => {
      let result = response.result.map((a) => {
        return {
          lat: a.latitude,
          lng: a.longitude,
          img_src: null,
          label: a.uuid,
          tipocolaborador: a.tipocolaborador,
        };
      });
      this.setState({
        motoristas: result,
      });
    });
  }
  changeEndereco(sugestion, focus = false, j) {
    let mydest = this.state.destinos;
    let endereco = sugestion.fullAddress.split(",");
    if (Number(j) === 999) {
      this.setState({
        origemautocomplete: sugestion,
        markerLocal: [
          {
            lat: sugestion.latitude,
            lng: sugestion.longitude,
            img_src: null,
            label: "Origem",
          },
        ],
        origem: endereco[0],
        numero: sugestion.numero,
        bairro: sugestion.bairro,
        cidade: sugestion.cidade,
        estado: sugestion.estado,
        ac_o_pos: [sugestion.latitude, sugestion.longitude],
        origemLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
        fullAddress: sugestion.fullAddress,
      });
      this.driversAround(sugestion.latitude, sugestion.longitude);
    } else {
      for (let a in mydest) {
        if (Number(a) === Number(j)) {
          mydest[a].autocomplete = sugestion;
          mydest[a].destino = endereco[0];
          mydest[a].numeroDestino = sugestion.numero;
          mydest[a].bairroDestino = sugestion.bairro;
          mydest[a].cidadeDestino = sugestion.cidade;
          mydest[a].fullAddress = sugestion.fullAddress;
          mydest[a].ac_pos = [sugestion.latitude, sugestion.longitude];
          mydest[a].lng = sugestion.longitude;
          mydest[a].lat = sugestion.latitude;
          mydest[a].latitude = sugestion.latitude;
          mydest[a].longitude = sugestion.longitude;
          //this.refs['numeroDestino' + a].focus();
        }
      }

      this.setState({
        destino: mydest[mydest.length - 1].destino,
        destinos: mydest,
        numeroDestino: mydest[mydest.length - 1].numeroDestino,
        bairroDestino: mydest[mydest.length - 1].bairroDestino,
        cidadeDestino: mydest[mydest.length - 1].cidadeDestino,
        d_fullAddress: mydest[mydest.length - 1].fullAddress,
        destinoLatLng: {
          lat: mydest[mydest.length - 1].latitude,
          lng: mydest[mydest.length - 1].lng,
        },
      });
    }
    if (focus) {
      //ReactDom.findDOMNode(this.refs.numeroDestino).focus();
    }
    setTimeout(() => {
      this.calculaValor();
    }, 2000);
    setTimeout(() => {
      this.organizaMarquerDestinos();
    }, 2000);
  }
  async organizaMarquerDestinos() {
    let marques = [];
    let destinos = this.state.destinos;

    for (let x = 0; x < destinos.length; x++) {
      const e = destinos[x];
      marques.push({
        lat: e.latitude,
        lng: e.longitude,
        img_src: null,
        label: "Destino " + (x + 1),
      });
    }

    await this.setState({ markerDest: marques });
  }

  changeOptions(newAddress) {
    this.setState({ options: newAddress });
  }
  changeEnderecoOrigem(sugestion, focus = false, j) {
    this.setState({
      markerLocal: [
        {
          lat: sugestion.latitude,
          lng: sugestion.longitude,
          img_src: null,
          label: "Origem",
        },
      ],
      selected: {
        ...this.state.selected,
        origem_endereco: sugestion.fullAddress,
        origem_numero: sugestion.numero,
        origem_bairro: sugestion.bairro,
        origemLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
        origem_cidade: sugestion.cidade,
      },
      origem_cidade: sugestion.cidade,
      origemLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
      origem_bairro: sugestion.bairro,
    });
    //if (focus) { ReactDom.findDOMNode(this.refs.numeroOrigem).focus(); } enrerecodestino" + j
  }
  changeEnderecoDestinoExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    let splited = newAddress.split(",");
    let parte1 = splited[0];
    //let parte2= number
    this.setState({
      selected: {
        ...this.state.selected,
        destino_endereco: parte1,
        destino_numero: number,
        destino_cidade: newCity,
      },
      destino_cidade: newCity,
    });
    if (focus) {
      //ReactDom.findDOMNode(this.refs.numeroDestino).focus();
    }
  }
  changeEnderecoOrigemExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    let splited = newAddress.split(",");
    let parte1 = splited[0];

    this.setState({
      selected: {
        ...this.state.selected,
        origem_endereco: parte1,
        origem_numero: number,
        origem_cidade: newCity,
        origem_estado: sugestion.estado,
      },
      origem_estado: sugestion.estado,
      origem_cidade: newCity,
    });
    if (focus) {
      //ReactDom.findDOMNode(this.refs.numeroOrigem).focus();
    }
  }
  changeEnderecoDestino(sugestion, focus = false, j) {
    let origem_endereco = sugestion.fullAddress.split(",");

    this.setState({
      selected: {
        ...this.state.selected,
        origem_endereco: origem_endereco,
        origem_numero: sugestion.numero,
        origem_bairro: sugestion.bairro,
        destinoLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
        origem_cidade: sugestion.cidade,
        origem_estado: sugestion.estado,
      },
      origem_cidade: sugestion.cidade,
      origem_estado: sugestion.estado,
      destinoLatLng: { lat: sugestion.latitude, lng: sugestion.longitude },
      origem_bairro: sugestion.bairro,
    });
    if (focus) {
      //ReactDom.findDOMNode(this.refs.numeroDestino).focus();
    }
  }
  changeEnderecoExtra(
    newAddress,
    newCity,
    number,
    focus = false,
    j,
    label,
    sugestion
  ) {
    let mydest = this.state.destinos;

    if (!newAddress) {
      newAddress = "";
    }

    if (Number(j) === 999) {
      this.setState({
        origem: newAddress,

        fullAddress: newAddress,
      });
    } else {
      for (let a in mydest) {
        if (Number(a) === Number(j)) {
          mydest[a].destino = newAddress;
          mydest[a].fullAddress = newAddress;
          this.setState({
            destinos: mydest,
          });
          if (focus) {
            //ReactDom.findDOMNode(this.refs["numeroDestino" + a]).focus();
          }
        }
      }
      this.setState({
        destino: newAddress,
        d_newAddress: newAddress,
      });
    }
  }

  async newSendTaxiReal() {
    console.log("this.state.suggestion", this.state);
    let email = this.state.suggestion.voucher_usuarios_email;
    let solicitante = this.state.func_data.id;
    let funcionario = this.state.suggestion.id;
    let telefone = this.state.telefone;
    let nome = this.state.passageiro;
    let uuid = this.state.uuid;
    let observacoes = this.state.referencia;
    let numero_carros = this.state.qtdCorridas;
    let departamento = Number(this.state.selection);
    let pgto = this.state.pagtoSelectedApi;
    let waypoints = [];

    waypoints.push({
      latitude: this.state.origemLatLng.lat,
      longitude: this.state.origemLatLng.lng,
      obs: !!this.state.complemento ? this.state.complemento : "",
      linha: this.state.fullAddress,
    });

    if (this.state.destinos.length > 0) {
      for (let tam = 0; tam < this.state.destinos.length; tam++) {
        let newObj = {
          latitude: this.state.destinos[tam].lat,
          longitude: this.state.destinos[tam].lng,
          linha: this.state.destinos[tam].fullAddress,
          obs:
            (!!this.state.destinos[tam].complemento
              ? this.state.destinos[tam].complemento + " "
              : "") +
            (!!this.state.destinos[tam].obs
              ? this.state.destinos[tam].obs
              : ""),
        };
        //if (this.state.tipocentral === 1) {
        waypoints.push(newObj);
        //}
      }
    }

let pix_pagseguro = {
  documento: this.state.pixCpf,
  nome: nome,
  email: email
}

    var dataCompleta = null;
    if (this.state.agendCheck) {
      let date = moment(this.state.dataAgend).format("YYYY-MM-DD");
      let hour = moment(this.state.dataAgend).format("HH:mm");

      dataCompleta = moment(date + "T" + hour + ":00")
        .utc()
        .format("YYYY-MM-DD HH:mm");
    }

    let corrida = await this.service.solicitarApi(
      funcionario,
      solicitante,
      departamento,
      waypoints,
      pgto,
      telefone,
      nome,
      email,
      numero_carros,
      1,
      observacoes,
      dataCompleta,
      this.state.uuid,
      pix_pagseguro
    );

    if (this.state.favCheck) {
      if (!this.state.origemautocomplete.favorito) {
        let auto = this.state.origemautocomplete;
        auto.favorito = true;
        let origem = {
          nome: auto.shortAddress,
          endereco: auto.fullAddress,
          autocomplete: auto,
          tagtipe: 0,
          usuario: this.props.childProps.user.id,
          uuid: "",
        };
        await this.service.criarTipo("favoritos", origem);
      }
      for (let index = 0; index < this.state.destinos.length; index++) {
        const element = this.state.destinos[index];
        if (!element.autocomplete.favorito) {
          let auto = element.autocomplete;
          auto.favorito = true;
          let destino = {
            nome: auto.shortAddress,
            endereco: auto.fullAddress,
            autocomplete: auto,
            tagtipe: 0,
            usuario: this.props.childProps.user.id,
            uuid: "",
          };
          await this.service.criarTipo("favoritos", destino);
        }
      }
    }

    if (!!corrida && corrida.result) {
        this.navigateTo({
          pathname: "/corrida-registrada",
          state: { id: corrida.solicitacao, pix: corrida.pix },
        });
    } else {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "OCORREU UM ERRO",
        podepedir: true,
      });
    }
  }

  async sendTaxiReal() {
    if (this.state.telefone !== "") {
      this.setState({ validation: "has-success" });
    } else {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "Por favor preencha seu telefone!",
        podepedir: true,
      });
      this.setState({ validation: "has-error" });
      return;
    }

    if (this.state.passageiro !== "") {
      this.setState({ validation: "has-success" });
    } else {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "Por favor preencha o passageiro!",
        podepedir: true,
      });
      this.setState({ validation: "has-error" });
      return;
    }

    if (this.state.origem === "") {
      this.navigateTo("/");
      return;
    }

    if (this.state.empresa_data.destino_obrigatorio) {
      if (this.state.destinos[0].destino === "") {
        this.setState({
          alertDisplay: "alert-danger",
          alertText: "Por favor preencha o destino!",
          podepedir: true,
        });
        return;
      }
    }

    let meuprices = this.state.prices[this.state.tiposelecionado];
    let codigos = [];

    codigos.push(this.state.tiposelecionado);

    if (this.state.tipo_cor) {
      let a = [];
      a.push(101);
      await this.setState({ corSelected: a });
    } else {
      let a = [];
      a.push(10);
      await this.setState({ corSelected: a });
    }

    if (this.state.pagtoSelected.length < 1) {
      this.setState({
        alertDisplay: "alert-danger",
        alertText: "Por favor selecione o metodo de pagamento!",
        podepedir: true,
      });
      return;
    }

    if (Number(this.state.pagtoSelected) === 112) {
      if (this.state.prices.vet) {
        meuprices = this.state.prices.vet;
      }
    }
    var dataCompleta = null;
    if (this.state.agendCheck) {
      let date = moment(this.state.dataAgend).format("YYYY-MM-DD");
      let hour = this.state.horaAgend.format("HH:mm");

      dataCompleta = moment(date + "T" + hour + ":00")
        .utc()
        .format("YYYY-MM-DD HH:mm");

      this.setState({ dataCompleta: dataCompleta });
    }

    if (this.state.favCheck) {
      if (!this.state.origemautocomplete.favorito) {
        let auto = this.state.origemautocomplete;
        auto.favorito = true;
        let origem = {
          nome: auto.shortAddress,
          endereco: auto.fullAddress,
          autocomplete: auto,
          tagtipe: 0,
          usuario: this.props.childProps.user.id,
          uuid: "",
        };
        await this.service.criarTipo("favoritos", origem);
      }
      for (let index = 0; index < this.state.destinos.length; index++) {
        const element = this.state.destinos[index];
        if (!element.autocomplete.favorito) {
          let auto = element.autocomplete;
          auto.favorito = true;
          let destino = {
            nome: auto.shortAddress,
            endereco: auto.fullAddress,
            autocomplete: auto,
            tagtipe: 0,
            usuario: this.props.childProps.user.id,
            uuid: "",
          };
          await this.service.criarTipo("favoritos", destino);
        }
      }
    }

    if (
      this.state.pagtoSelected.indexOf("112") > -1 ||
      this.state.pagtoSelected.indexOf(112) > -1
    ) {
      let novoVetorDestino = [];
      let mydest = this.state.destinos;

      if (this.state.destinos.length > 0) {
        for (let tam = 0; tam < this.state.destinos.length; tam++) {
          let newObj = {
            ...mydest[tam],
            key: "destInt" + tam,
            order: tam,
            Address: {
              fullAddress: mydest[tam].fullAddress,
              shortAddress: mydest[tam].destino,
              autocomplete: mydest[tam].autocomplete,
            },
            latitude: mydest[tam].lat,
            longitude: mydest[tam].lng,
          };
          novoVetorDestino.push(newObj);
        }
      }

      let enderecoscomponentes = {
        origem: this.state.origemautocomplete,
        destino:
          this.state.destinos[this.state.destinos.length - 1].autocomplete,
      };
      if (this.state.retornoOriginalCheck) {
        novoVetorDestino.push({
          Address: {
            fullAddress: this.state.origemautocomplete.fullAddress,
            shortAddress: this.state.origem,
            autocomplete: this.state.origemautocomplete,
          },
          autocomplete: this.state.origemautocomplete,
          bairro: this.state.bairro,
          cidade: this.state.cidade,
          estado: this.state.estado,
          estadoDestino: this.state.estado,
          numeroDestino: this.state.numero,
          bairroDestino: this.state.bairro,
          cidadeDestino: this.state.cidade,
          destino: this.state.origem,
          fullAddress: this.state.origemautocomplete.fullAddress,
          latitude: this.state.origemLatLng.lat,
          lat: this.state.origemLatLng.lat,
          longitude: this.state.origemLatLng.lng,
          lng: this.state.origemLatLng.lng,
          contato: this.state.passageiro,
          obs: "retorno ao remetente",
          parada: false,
          retorno: true,
          key: "destInt" + novoVetorDestino.length,
          order: novoVetorDestino.length,
        });
      }
      if (this.state.suggestion.id) {
        //VALORCALC
        let valorCalculado = 0;

        let priceusar = meuprices.comissoes.c01;
        if (Number(this.state.pagtoSelected) === Number(10)) {
          priceusar = meuprices.comissoes.c01;
        }
        if (Number(this.state.pagtoSelected) === Number(15)) {
          priceusar = meuprices.comissoes.vis;
        }
        if (Number(this.state.pagtoSelected) === Number(100)) {
          priceusar = meuprices.comissoes.crd;
        }
        if (Number(this.state.pagtoSelected) === Number(112)) {
          priceusar = meuprices.comissoes.vet;
        }
        valorCalculado = priceusar.valorComtaxas;
        //VALORCALC

        if (
          Number(this.state.suggestion.valorlimit) >
            Number(this.state.suggestion.saldo_gasto) +
              Number(this.state.valorCorridaFloat) ||
          this.funcDep(this.state.selection)
        ) {
          if (this.BlockDay() || this.state.suggestion.calendar === null) {
            this.service
              .orderTaxi({
                caridade: this.props.childProps.func.caridade,
                destinos: novoVetorDestino,
                enderecoscomponentes: enderecoscomponentes,
                emailcliente: this.state.suggestion.voucher_usuarios_email,
                voucher_funcionario_solicitante: this.state.func_data.id,
                voucher_funcionario: this.state.suggestion.id,
                telefone: this.state.telefone,
                nome: this.state.passageiro,
                endereco: this.state.origem,
                numero: this.state.numero,
                codigos: codigos.join(" ").toUpperCase().trim(),
                latitude: this.state.origemLatLng.lat,
                longitude: this.state.origemLatLng.lng,
                enderecoDestino:
                  this.state.destinos[this.state.destinos.length - 1].destino,
                numeroDestino:
                  this.state.destinos[this.state.destinos.length - 1]
                    .numeroDestino,
                cidadeDestino: this.state.cidadeDestino,
                bairroDestino: this.state.bairroDestino,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                estadodestino: this.state.estadodestino,
                latitudeDestino:
                  this.state.destinoLatLng.lat === ""
                    ? null
                    : this.state.destinoLatLng.lat,
                longitudeDestino:
                  this.state.destinoLatLng.lng === ""
                    ? null
                    : this.state.destinoLatLng.lng,
                tipo_pagto: [parseInt(this.state.pagtoSelected, 10)],
                tabelavalores: meuprices,
                valorTabelada: valorCalculado,
                data_hora_agendamento: dataCompleta,
                observacoes: this.state.referencia,
                complemento: this.state.complemento,
                antecipar: parseInt(this.state.antecipar),
                atendente: "Portal",
                numero_carros: this.state.qtdCorridas,
                departamento: Number(this.state.selection),
              })
              .then(async (result) => {
                if (result) {
                  this.navigateTo({
                    pathname: "/corrida-registrada",
                    state: { id: result.solicitacao },
                  });
                } else {
                  let erroextra = "";
                  if (result.err) {
                    erroextra = " : " + result.err;
                  }
                  this.setState({
                    alertDisplay: "alert-danger",
                    alertText: "Ocorreu um erro" + erroextra,
                    podepedir: true,
                  });
                }
              });
          } else {
            alert(
              "Não é possivel chamar a corrida fora do período predeterminado!"
            );
          }
        } else {
          alert("Saldo insuficiente para chamar a corrida");
        }
      }
    } else {
      if (
        (this.state.pagtoSelected.indexOf("113") > -1 ||
          this.state.pagtoSelected.indexOf(113) > -1) &&
        this.state.moip
      ) {
        let novoVetorDestino = [];
        let mydest = this.state.destinos;

        if (this.state.destinos.length > 0) {
          for (let tam = 0; tam < this.state.destinos.length; tam++) {
            let newObj = {
              ...mydest[tam],
              key: "destInt" + tam,
              order: tam,
              Address: {
                fullAddress: mydest[tam].fullAddress,
                shortAddress: mydest[tam].destino,
                autocomplete: mydest[tam].autocomplete,
              },
              latitude: mydest[tam].lat,
              longitude: mydest[tam].lng,
            };
            //if (this.state.tipocentral === 1) {
            novoVetorDestino.push(newObj);
            //}
          }
        }
        // if (this.state.tipocentral === 1) {
        //   if (novoVetorDestino.length < 2) {
        //     novoVetorDestino.pop();
        //   }
        // }
        let enderecoscomponentes = {
          origem: this.state.origemautocomplete,
          destino:
            this.state.destinos[this.state.destinos.length - 1].autocomplete,
        };
        if (this.state.retornoOriginalCheck) {
          novoVetorDestino.push({
            Address: {
              fullAddress: this.state.origemautocomplete.fullAddress,
              shortAddress: this.state.origem,
              autocomplete: this.state.origemautocomplete,
            },
            autocomplete: this.state.origemautocomplete,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadoDestino: this.state.estado,
            numeroDestino: this.state.numero,
            bairroDestino: this.state.bairro,
            cidadeDestino: this.state.cidade,
            destino: this.state.origem,
            fullAddress: this.state.origemautocomplete.fullAddress,
            latitude: this.state.origemLatLng.lat,
            lat: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            lng: this.state.origemLatLng.lng,
            contato: this.state.passageiro,
            obs: "retorno ao remetente",
            parada: false,
            retorno: true,
            key: "destInt" + novoVetorDestino.length,
            order: novoVetorDestino.length,
          });
        }

        //VALORCALC
        let valorCalculado = 0;
        let priceusar = meuprices.comissoes.c01;
        if (Number(this.state.pagtoSelected) === Number(10)) {
          priceusar = meuprices.comissoes.c01;
        }
        if (Number(this.state.pagtoSelected) === Number(15)) {
          priceusar = meuprices.comissoes.vis;
        }
        if (Number(this.state.pagtoSelected) === Number(100)) {
          priceusar = meuprices.comissoes.crd;
        }
        if (Number(this.state.pagtoSelected) === Number(112)) {
          priceusar = meuprices.comissoes.vet;
        }
        valorCalculado = priceusar.valorComtaxas;
        //VALORCALC

        this.service
          .orderTaxi({
            caridade: this.props.childProps.func.caridade,
            destinos: novoVetorDestino,
            enderecoscomponentes: enderecoscomponentes,
            moip: this.state.moip,
            telefone: this.state.telefone,
            nome: this.state.passageiro,
            emailcliente: this.state.suggestion.voucher_usuarios_email,
            voucher_funcionario: this.state.suggestion.id,
            voucher_funcionario_solicitante: this.state.func_data.id,
            endereco: this.state.origem,
            numero: this.state.numero,
            latitude: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            enderecoDestino:
              this.state.destinos[this.state.destinos.length - 1].destino,
            numeroDestino:
              this.state.destinos[this.state.destinos.length - 1].numeroDestino,
            cidadeDestino: this.state.cidadeDestino,
            bairroDestino: this.state.bairroDestino,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadodestino: this.state.estadodestino,
            latitudeDestino:
              this.state.destinoLatLng.lat === ""
                ? null
                : this.state.destinoLatLng.lat,
            longitudeDestino:
              this.state.destinoLatLng.lng === ""
                ? null
                : this.state.destinoLatLng.lng,
            tipo_pagto: [parseInt(this.state.pagtoSelected, 10)],
            codigos: codigos.join(" ").toUpperCase().trim(),
            tabelavalores: meuprices,
            valorTabelada: valorCalculado,
            data_hora_agendamento: dataCompleta,
            observacoes: this.state.referencia,
            complemento: this.state.complemento,
            antecipar: parseInt(this.state.antecipar),
            atendente: "Portal",
            numero_carros: this.state.qtdCorridas,
          })
          .then(async (result) => {
            if (result) {
              this.navigateTo({
                pathname: "/corrida-registrada",
                state: { id: result.solicitacao },
              });
            } else {
              this.setState({
                alertDisplay: "alert-danger",
                alertText: "OCORREU UM ERRO",
                podepedir: true,
              });
            }
          });
      } else {
        let novoVetorDestino = [];
        let mydest = this.state.destinos;

        if (this.state.destinos.length > 0) {
          for (let tam = 0; tam < this.state.destinos.length; tam++) {
            let newObj = {
              ...mydest[tam],
              key: "destInt" + tam,
              order: tam,
              Address: {
                fullAddress: mydest[tam].fullAddress,
                shortAddress: mydest[tam].destino,
                autocomplete: mydest[tam].autocomplete,
              },
              latitude: mydest[tam].lat,
              longitude: mydest[tam].lng,
            };
            //if (this.state.tipocentral === 1) {
            novoVetorDestino.push(newObj);
            //}
          }
        }
        // if (this.state.tipocentral === 1) {
        //   if (novoVetorDestino.length < 2) {
        //     novoVetorDestino.pop();
        //   }
        // }
        let enderecoscomponentes = {
          origem: this.state.origemautocomplete,
          destino:
            this.state.destinos[this.state.destinos.length - 1].autocomplete,
        };
        if (this.state.retornoOriginalCheck) {
          novoVetorDestino.push({
            Address: {
              fullAddress: this.state.origemautocomplete.fullAddress,
              shortAddress: this.state.origem,
              autocomplete: this.state.origemautocomplete,
            },
            autocomplete: this.state.origemautocomplete,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadoDestino: this.state.estado,
            numeroDestino: this.state.numero,
            bairroDestino: this.state.bairro,
            cidadeDestino: this.state.cidade,
            destino: this.state.origem,
            fullAddress: this.state.origemautocomplete.fullAddress,
            latitude: this.state.origemLatLng.lat,
            lat: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            lng: this.state.origemLatLng.lng,
            contato: this.state.passageiro,
            obs: "retorno ao remetente",
            parada: false,
            retorno: true,
            key: "destInt" + novoVetorDestino.length,
            order: novoVetorDestino.length,
          });
        }

        //VALORCALC
        let valorCalculado = 0;

        let priceusar = meuprices.comissoes.c01;
        if (Number(this.state.pagtoSelected) === Number(10)) {
          priceusar = meuprices.comissoes.c01;
        }
        if (Number(this.state.pagtoSelected) === Number(15)) {
          priceusar = meuprices.comissoes.vis;
        }
        if (Number(this.state.pagtoSelected) === Number(100)) {
          priceusar = meuprices.comissoes.crd;
        }
        if (Number(this.state.pagtoSelected) === Number(112)) {
          priceusar = meuprices.comissoes.vet;
        }
        valorCalculado = priceusar.valorComtaxas;
        //VALORCALC

        this.service
          .orderTaxi({
            caridade: this.props.childProps.func.caridade,
            destinos: novoVetorDestino,
            enderecoscomponentes: enderecoscomponentes,
            telefone: this.state.telefone,
            nome: this.state.passageiro,
            emailcliente: this.state.suggestion.voucher_usuarios_email,
            voucher_funcionario: this.state.suggestion.id,
            voucher_funcionario_solicitante: this.state.func_data.id,
            endereco: this.state.origem,
            numero: this.state.numero,
            latitude: this.state.origemLatLng.lat,
            longitude: this.state.origemLatLng.lng,
            enderecoDestino:
              this.state.destinos[this.state.destinos.length - 1].destino,
            numeroDestino:
              this.state.destinos[this.state.destinos.length - 1].numeroDestino,
            cidadeDestino: this.state.cidadeDestino,
            bairroDestino: this.state.bairroDestino,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            estadodestino: this.state.estadodestino,
            latitudeDestino:
              this.state.destinoLatLng.lat === ""
                ? null
                : this.state.destinoLatLng.lat,
            longitudeDestino:
              this.state.destinoLatLng.lng === ""
                ? null
                : this.state.destinoLatLng.lng,
            tipo_pagto: [parseInt(this.state.pagtoSelected, 10)],
            codigos: codigos.join(" ").toUpperCase().trim(),
            tabelavalores: meuprices,
            valorTabelada: valorCalculado,
            data_hora_agendamento: dataCompleta,
            observacoes: this.state.referencia,
            complemento: this.state.complemento,
            antecipar: parseInt(this.state.antecipar),
            atendente: "Portal",
            numero_carros: this.state.qtdCorridas,
          })
          .then(async (result) => {
            if (result) {
              this.navigateTo({
                pathname: "/corrida-registrada",
                state: { id: result.solicitacao },
              });
            } else {
              this.setState({
                alertDisplay: "alert-danger",
                alertText: "OCORREU UM ERRO",
                podepedir: true,
              });
            }
          });
      }
    }
  }

  async sendTaxiPix() {
    await this.setState({
      podepedir: false,
      showPix: false,
    });
    this.pedir = setTimeout(this.newSendTaxiReal, 2000);
  }

  async sendTaxi() {
    await this.setState({
      podepedir: false,
    });
    if (this.state.pagtoSelectedApi.indexOf("PIX") > -1){
      this.showModalPix();
    } else {
      this.pedir = setTimeout(this.newSendTaxiReal, 2000);
    }
  }
}
